/* eslint-disable no-undef */

import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";

import Root from "components/routes/Root";
import "./index.css";
import App from "components/App";
import SuspenseLoader from "components/common/loaders/SuspenseLoader";
import * as serviceWorker from "./serviceWorker";
import DocumentTitle from "components/common/DocumentTitle";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: true,
      blockAllMedia: false,
    }),
  ],

  tracesSampleRate: process.env.REACT_APP_ENV === "production" ? 0.1 : 1.0,
  tracePropagationTargets: [process.env.REACT_APP_API_BASE_ENDPOINT || ""],

  replaysSessionSampleRate:
    process.env.REACT_APP_ENV === "production" ? 0.05 : 1.0,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <App>
    <React.Suspense fallback={<SuspenseLoader />}>
      <DocumentTitle />
      <Root />
    </React.Suspense>
  </App>,
  document.getElementById("root") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
